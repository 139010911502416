import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTags,
  faMapMarkerAlt,
  faChalkboardTeacher,
  faUserTag,
  faEnvelope,
  faPhone,
} from "@fortawesome/pro-light-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import EventiSlider from "../../components/EventiSlider/EventiSlider";

export default function ProviderPage({ data }) {
  const { providersJson: provider } = data;

  return (
    <Layout pageTitle={`Provider Agenas ${provider.ragione_sociale}`}>
      <section className="position-relative">
        <StaticImage
          src="../../images/header-provider.jpg"
          className="img-header-provider"
          alt={provider.ragione_sociale}
          title={provider.ragione_sociale}
        />
        <div className="container-fluid px-0 py-5 container-absolute">
          <div className="container py-5">
            {provider.slug === "new-master-srl" && (
              <StaticImage
                src="../../images/providers/newmaster/logo.png"
                alt="New Master"
                title="New Master"
              />
            )}
            {provider.slug === "comunicazion-and-venti-di-francesco-billi-e-c-snc" && (
              <StaticImage
                src="../../images/providers/comunicazioneventi/logo.png"
                alt="Comunicazion&venti"
                title="Comunicazion&venti"
              />
            )}
            {provider.slug === "biomedical-technologies-srl" && (
              <StaticImage
                src="../../images/providers/biomedical/logo.png"
                alt="Biomedical Technologies"
                title="Biomedical Technologies"
              />
            )}
            <h1 className="text-center text-white">
              {provider.ragione_sociale}
              <span className="d-block font-size-h2">
                Provider Agenas ECM N. {provider.id_provider}
                <br />
                REGIONE {provider.regione}
              </span>
            </h1>
          </div>
        </div>
      </section>
      <div className="container-fluid px-5 my-5">
        <div className="row">
          <div className="col-12 col-md-3 text-center">
            <h2 className="text-primary">
              <FontAwesomeIcon
                icon={faTags}
                className="mb-2"
                style={{ fontSize: 64 }}
              />
              <br />
              Tipologia provider
            </h2>
            <p>{provider.tipologia_provider}</p>
          </div>
          <div className="col-12 col-md-3 text-center">
            <h2 className="text-primary">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="mb-2"
                style={{ fontSize: 64 }}
              />
              <br />
              Sede legale
            </h2>
            <p>{provider.sede_legale}</p>
          </div>
          <div className="col-12 col-md-3 text-center">
            <h2 className="text-primary">
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                className="mb-2"
                style={{ fontSize: 64 }}
              />
              <br />
              Stato accreditamento
            </h2>
            <p>{provider.stato_accreditamento}</p>
          </div>
          <div className="col-12 col-md-3 text-center">
            <h2 className="text-primary">
              <FontAwesomeIcon
                icon={faUserTag}
                className="mb-2"
                style={{ fontSize: 64 }}
              />
              <br />
              Tipologia formazione
            </h2>
            <p>{provider.tipo_formazione}</p>
          </div>
        </div>
      </div>
      {provider.slug === "new-master-srl" && (
        <ProviderNewMaster eventiRes={data.allEventiResNewMaster.nodes} />
      )}
      {provider.slug ===
        "comunicazion-and-venti-di-francesco-billi-e-c-snc" && (
        <ProviderComunicazionEventi />
      )}
      {provider.slug === "biomedical-technologies-srl" && (
        <ProviderBiomedical />
      )}
      {[
        "new-master-srl",
        "comunicazion-and-venti-di-francesco-billi-e-c-snc",
        "biomedical-technologies-srl",
      ].includes(provider.slug) !== true && (
        <div className="container p-5 my-5 text-center">
          <h2 className="titolo-sezione">Contatti</h2>
          <p>
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> Email:{" "}
            <a href={`mailto:${provider.email}`}>{provider.email}</a>
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone} className="mr-2" /> Telefono:{" "}
            <a href={`tel:+39${provider.telefono}`}>{provider.telefono}</a>
          </p>
        </div>
      )}
    </Layout>
  );
}

function ProviderNewMaster({ eventiRes }) {
  return (
    <>
      <div className="container text-center">
        <p>
          La <b>New Master</b> nasce nel <b>2003</b>, è una società di
          formazione che opera nell'organizzazione di eventi nel settore
          sanitario della riabilitazione e vanta uno staff di docenti
          accreditati dalle scuole e dalle varie associazioni nazionali ed
          internazionali.
        </p>
        <p>
          Il <b>dinamismo</b> che caratterizza questa società di formazione,
          viene dall'incessabile energia della direzione e dei collaboratori,
          che con grande passione, dispensano il loro lavoro, e che ha portato
          la società a raggiungere un importantissimo obiettivo: consentire ai
          professionisti del settore di conquistare quei{" "}
          <b>livelli di conoscenza espressi in campo internazionale</b>.
        </p>
        <p>
          Nel <b>2007</b> la New Master si certifica per offrire ai propri
          clienti la garanzia che l'azienda a cui si rivolgono operi secondo i
          principi di efficienza produttiva ed efficacia sistemica puntando
          sulla qualità dei prodotti/servizi resi.
        </p>
        <p>
          Nel <b>2011</b> raggiunge un ulteriore obiettivo nel riconoscimento di
          Provider standard accreditato dal Ministero Della Salute per la{" "}
          <b>formazione ECM</b> a livello nazionale (Provider n. 441). I nostri
          piani formativi annuali sono motivo di grande orgoglio, sia per lo
          spessore internazionale dei corsi proposti, sia per la grande
          partecipazione delle figure professionali a cui sono rivolti.
        </p>
        <p>
          Questo apprezzamento, costante negli anni, degli operatori del settore
          ci conferma le nostre scelte e ci dà motivo di pensare che le nostre
          strategie aziendali siano quelle giuste.
        </p>
        <p>
          La New Master svolge la sua attività di organizzazione eventi in{" "}
          <b>tutto il territorio nazionale</b>, in particolar modo a Roma,
          Milano e Mestre.
        </p>
        <div className="my-4">
          <StaticImage
            src="../../images/providers/newmaster/learn-from-the-bests.png"
            alt="New Master - I migliori corsi di formazione per fisioterapisti"
            title="New Master - I migliori corsi di formazione per fisioterapisti"
          />
        </div>
        <h2 className="my-4 text-center titolo-sezione">Contatti</h2>
        <p>
          Informazioni generali, sedi, date e disponibilità:{" "}
          <a href="mailto:infosito@newmaster.it">infosito@newmaster.it</a> o
          chiamare Edoardo al numero{" "}
          <a href="tel:+390651600107">06 51 600 107</a>
        </p>
        <p>
          Crediti formativi e amministrazione:{" "}
          <a href="mailto:amministrazione@newmaster.it">
            amministrazione@newmaster.it
          </a>{" "}
          o chiamare Serena al numero{" "}
          <a href="tel:+393384832950">338 48 32 950</a>
        </p>
        <p>
          Corsi residenziali:{" "}
          <a href="mailto:direzione@newmaster.it">direzione@newmaster.it</a> o
          chiamare Laura al numero
          <a href="tel:+393355964543">335 59 64 543</a>
        </p>
        <p>
          Fatturazione:{" "}
          <a href="mailto:fatturazione@newmaster.it">
            fatturazione@newmaster.it
          </a>{" "}
          o chiamare Benedetta al numero
          <a href="tel:+393392408377">339 24 08 377</a>
        </p>
      </div>
      <div className="container-fluid">
        <div className="row px-5 py-3">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Corsi ECM RES in evidenza
            </h2>
            <EventiSlider eventi={eventiRes} />
          </div>
        </div>
        <div className="row py-5">
          <div className="col-12 text-center fw-bold titolo-sezione">
            <p>Vuoi ricevere informazioni sui nostri corsi?</p>
            <p>
              Chiamaci <a href="tel:+390651600107">06 51 600 107</a> o inviaci
              una mail a{" "}
              <a href="mailto:infosito@newmaster.it">infosito@newmaster.it</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function ProviderComunicazionEventi() {
  return (
    <>
      <div className="container text-center">
        <p>
          <b>Comunicazion&venti</b> è un'agenzia attiva dal <b>1997</b>,
          specializzata nell’organizzazione e gestione di eventi, in ambito
          nazionale e internazionale, e nella comunicazione integrata.
        </p>
        <p>
          Oltre a questo, in qualità di <b>Provider ECM</b>, possiamo svolgere
          tutte le pratiche richieste dalla Commissione Ministeriale per la
          Formazione Continua, dalla Registrazione degli Eventi fino
          all’ottenimento dei Crediti Formativi nell’organizzazione dei
          congressi medici.
        </p>
        <p>
          Il nostro staff, formato da meetìng planners, art director, grafici,
          giornalisti, web designers e account è in grado di operare in tempi
          rapidi e in maniera <b>completa</b>, <b>qualificata</b> e{" "}
          <b>conveniente</b>.
        </p>
        <div className="my-4">
          <StaticImage
            src="../../images/providers/comunicazioneventi/ecm.png"
            alt="Comunicazion&venti"
            title="Comunicazion&venti"
          />
        </div>
        <h2 className="my-4 text-center titolo-sezione">Contatti</h2>
        <p>
          <a href="mailto:segreteria@comunicazioneventi.it">
            segreteria@comunicazioneventi.it
          </a>
          <br />
          Pec:{" "}
          <a href="mailto:comunicazioneventi@pec.it">
            comunicazioneventi@pec.it
          </a>
          <br />
          <a href="tel:+390543720901">0543 720901</a>
        </p>
      </div>
      <div className="container-fluid">
        <div className="row py-5">
          <div className="col-12 text-center fw-bold titolo-sezione">
            <p>Vuoi ricevere informazioni sui nostri corsi?</p>
            <p>
              Chiamaci <a href="tel:+390543720901">0543 720901</a> o inviaci una
              mail a{" "}
              <a href="mailto:segreteria@comunicazioneventi.it">
                segreteria@comunicazioneventi.it
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function ProviderBiomedical() {
  return (
    <>
      <div className="container text-center">
        <p>
          La <b>Biomedical Technologies</b> opera da oltre <b>35 anni</b> nel
          settore dell'organizzazione di eventi congressuali, con particolare
          attenzione alla realizzazione di <b>eventi medici</b> nell'area della
          Salute della Donna e della Riproduzione Umana.
        </p>
        <p>
          Dal <b>1981</b> supportiamo i nostri clienti nell’organizzazione di
          eventi di successo in tutto il mondo, fornendo assistenza e consulenza
          personalizzate a partire dalla progettazione fino alla realizzazione
          di ogni evento.
        </p>
        <p>
          Le nostre competenze sono cresciute nel tempo, permettendoci di
          diventare <b>leader nel settore della MICE industry</b>.
        </p>
        <p>
          Dal 2017, con il brand BTcongress, abbiamo esteso i nostri servizi
          affiancando all’organizzazione di congressi e corsi professionali
          anche la realizzazione di programi incentive, il destination
          management e l’association management.
        </p>
        <p>
          <b>Focus sul cliente</b>
          <br />
          Ci impegnamo a fornire ai nostri clienti con servizi su misura, capaci
          di rispondere ai loro specifici bisogni e alle loro richieste.
        </p>
        <p>
          <b>Integrità</b>
          <br />
          Ci impegnamo ad operare con trasparenza in ogni apetto del nostro
          lavoro.
        </p>
        <p>
          <b>Innovazione</b>
          <br />
          Siamo costantemente alla ricerca di soluzioni nuove ed innovative
        </p>
        <p>
          <b>Teamwork</b>
          <br />
          Crediamo che il lavoro in team sia essenziale per fornire servizi e
          soluzioni di altissimo livello.
        </p>
        <div className="my-4">
          <StaticImage
            src="../../images/providers/biomedical/plane-about.png"
            alt="Biomedical Technologies"
            title="Biomedical Technologies"
          />
        </div>
        <h2 className="my-4 text-center titolo-sezione">Contatti</h2>
        <div className="row">
          <div className="col-12 col-md-6">
            <p>
              Sede principale
              <br />
              <a href="tel:+39070340293">+39 070 340293</a>
              <br />
              <a href="mailto:info@btcongress.com">info@btcongress.com</a>
              <br />
              Via P. Cugia 1, Cagliari, Italia
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p>
              Ufficio comunicazione
              <br />
              <a href="tel:+39068546198">+39 06 8546198</a>
              <br />
              <a href="mailto:marketing@btcongress.com">
                marketing@btcongress.com
              </a>
              <br />
              Via Metauro 19, Roma, Italia
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row py-5">
          <div className="col-12 text-center fw-bold titolo-sezione">
            <p>Vuoi ricevere informazioni sui nostri corsi?</p>
            <p>
              Chiamaci <a href="tel:+39070340293">+39 070 340293</a> o inviaci
              una mail a{" "}
              <a href="mailto:info@btcongress.com">info@btcongress.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ($id: String) {
    providersJson(id: { eq: $id }) {
      ragione_sociale
      regione
      sede_legale
      stato_accreditamento
      telefono
      tipo_formazione
      tipologia_provider
      slug
      professioni
      id_provider
      id
      email
      data_aggiornamento
    }
    allEventiResNewMaster: allEventiJson(
      limit: 11
      filter: { provider_id: { eq: "441" }, tipo_evento: { eq: "RES" } }
      sort: { fields: data_fine_timestamp, order: DESC }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
  }
`;
